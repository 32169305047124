@font-face {
  font-family: 'Benzin';
  src: local('☞Benzin ExtraBold'), local('Benzin-ExtraBold'),
  url('Benzin-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: local('Futura PT Demi'), local('FuturaPT-Demi'),
  url('FuturaPT-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Book';
  src: local('Futura PT Book'), local('FuturaPT-Book'),
  url('FuturaPT-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Bold'), local('FuturaPT-Bold'),
  url('FuturaPT-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Medium'), local('FuturaPT-Medium'),
  url('FuturaPT-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: local('Futura PT Cond Medium'), local('FuturaPTCond-Medium'),
  url('FuturaPTCond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Black'), local('Gilroy-Black'),
  url('Gilroy-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
  url('Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
  url('Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
