@import "src/assets/styles/variables";

.bgTop {
  background-image: url("../../assets/images/top-bg.svg");
  background-position: top center;
  background-repeat: no-repeat;
}

.bg1 {
  background-image: url("../../assets/images/top-bg.svg");
  background-position: bottom 130px center;
  background-repeat: no-repeat;
}

//.bg2 {
//  background-image: url("../../assets/images/top-bg.svg");
//  background-position: top center;
//  background-repeat: no-repeat;
//}

.bg3 {
  background-image: url("../../assets/images/top-bg.svg");
  background-position: top center;
  background-repeat: no-repeat;
}

@media(max-width: $mobileWidth) {
  .bgTop {
    background-size: 1185px 529px;
  }

  .bg1 {
    background-size: 1185px 529px;
    background-position: bottom 250px center;
  }

  .bg2 {
    background-size: 1185px 529px;
    background-position: bottom center;
  }

  .bg3 {
    background: none;
  }
}
