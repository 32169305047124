.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.imgWrap {
    width: 80%;
    height: 80%;
}

.AppImg {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: contain;
}

@media (max-width: 768px) {
    .imgWrap {
        width: 95%;
        height: 95%;
    }
}
